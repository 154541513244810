import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, CircularProgress, Snackbar, Alert, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../apiClient';
import AuctionTable from './AuctionTable';
import DeleteConfirmationDialog from '../AuctionListPage/DeleteConfirmationDialog';
import { handleAxiosError } from '../../../utils/handleAxiosError';
import { CurrentUser } from '../../../models/interfaces';

interface Auction {
  _id: string;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  status: string;
  auctionItems: string[];
}

interface AuctionManagementPageProps {
  loginUser: CurrentUser;
}

const AuctionManagementPage: React.FC<AuctionManagementPageProps> = ({ loginUser }) => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAuctionId, setSelectedAuctionId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAuctions();
  }, []);

  const fetchAuctions = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.get<Auction[]>('/auctions');
      setAuctions(response.data); // Use response.data directly
    } catch (error) {
      console.error('Error fetching auctions:', error);
      const message = handleAxiosError(error) || 'Failed to fetch auctions';
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (auctionId: string) => {
    setSelectedAuctionId(auctionId);
    setOpenDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedAuctionId) return;
    try {
      await apiClient.delete(`/auctions/${selectedAuctionId}`);
      setAuctions((prev) => prev.filter((auction) => auction._id !== selectedAuctionId));
      setSuccessMessage('Auction deleted successfully.');
    } catch (error) {
      console.error('Error deleting auction:', error);
      const message = handleAxiosError(error) || 'Failed to delete auction';
      setErrorMessage(message);
    } finally {
      setOpenDeleteModal(false);
      setSelectedAuctionId(null);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
    setSelectedAuctionId(null);
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Auction Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/auctions/add')}
        style={{ marginBottom: '20px' }}
      >
        Add New Auction
      </Button>
      <Paper>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <AuctionTable auctions={auctions} onDelete={handleDeleteClick} />
        )}
      </Paper>
      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={openDeleteModal}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        message="Are you sure you want to delete this auction?"
        deleteActionText="Delete"
      />
      {/* Notifications */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AuctionManagementPage;
