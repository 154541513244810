// src/components/login/LoginPage.tsx

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography, Container, Alert, useTheme, useMediaQuery } from '@mui/material';
import { generateCodeVerifier, generateCodeChallenge } from './pkce';
import GoogleIcon from '../icons/GoogleIcon'; // Ensure this is correctly imported

const LoginPage: React.FC = () => {
  const { login, isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Extract 'from' from location.state or default to '/'
  const from = (location.state as { from?: string })?.from || '/';

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      await login(email, password);
      // Navigation will be handled in useEffect
    } catch (err: any) {
      setError(err.message || 'Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (user?.hasCompletedOnboarding) {
        navigate(from, { replace: true });
      } else {
        navigate('/auth/onboarding', { replace: true });
      }
    }
  }, [isLoggedIn, user, navigate, from]);

  const handleGoogleLogin = async () => {
    try {
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallenge(codeVerifier);
      localStorage.setItem('code_verifier', codeVerifier); // Store code_verifier securely

      const domain = process.env.REACT_APP_COGNITO_DOMAIN;
      const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
      const redirectUri = process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000/oauth2/idpresponse';
      const responseType = 'code'; // Authorization Code Grant
      const scope = 'openid profile email aws.cognito.signin.user.admin'; // Added required scope
      const codeChallengeMethod = 'S256';
      const identityProvider = 'Google'; // Ensure this matches your Cognito IdP

      if (!domain || !clientId) {
        setError('Missing Cognito domain or client ID.');
        console.error('Missing Cognito domain or client ID.');
        return;
      }

      const authorizeUrl = `https://${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&scope=${encodeURIComponent(
        scope
      )}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}&identity_provider=${identityProvider}`;

      console.log('Redirecting to OAuth2 URL:', authorizeUrl); // Logging for debugging
      window.location.href = authorizeUrl;
    } catch (error: any) {
      setError(`Failed to initiate Google login: ${error.message}`);
      console.error('Failed to initiate Google login:', error);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url('/background1.jpg')`, // Ensure the image path is correct
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ minHeight: '30px' }} />
      <Container maxWidth="sm">
        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: 3,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            mx: isMobile ? 'auto' : 'unset',
            ml: isMobile ? 'auto' : 4,
            mr: isMobile ? 'auto' : 'unset',
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {error}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleLogin}
              sx={{
                mt: 1,
                mb: 2,
                textTransform: 'none',
                color: '#555555',
                borderColor: '#4285F4',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  borderColor: '#4285F4',
                  backgroundColor: '#f1f1f1',
                },
              }}
            >
              Login with Google
            </Button>
            <Typography variant="body2" align="center" sx={{ paddingTop: 5 }}>
              Don't have an account? <Link to="/auth/signup">Sign Up</Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
