// src/components/add-auction/fields/diamondFields.ts
import { formatNumber, parseFormattedNumber } from './numberUtils';
import { capitalize } from 'lodash';

export const calculateTitleForDiamond = (productFields: any): string => {
  const {
    caratWeight,
    colorType,
    color,
    fancyColorIntensity,
    fancyColorOvertone,
    fancyColorMainStone,
    clarityGrade,
    cut,
    shape,
    certification,
  } = productFields;

  let titleParts = [];

  if (caratWeight) {
    const formatted = formatNumber(caratWeight, {
      decimalScale: 2,
      fixedDecimalScale: true,
      allowNegative: false,
      decimalSeparator: '.',
      thousandSeparator: ',',
    });
    titleParts.push(`${formatted}tcw`);
  }

  // Color / Natural Colored
  if (colorType === 'Natural Colored' && color) {
    titleParts.push(`${color} Color`);
  } else if (colorType === 'Natural White') {
    const fancyColor = [fancyColorIntensity, fancyColorOvertone, fancyColorMainStone].filter(Boolean).join(' ');
    if (fancyColor) {
      titleParts.push(`Fancy ${fancyColor}`);
    }
  }

  if (clarityGrade) {
    titleParts.push(capitalize(clarityGrade));
  }

  if (cut) {
    titleParts.push(capitalize(cut));
  }

  if (shape) {
    titleParts.push(capitalize(shape));
  }

  return titleParts.join(' ');
};

export const diamondFields = [
  {
    name: 'stoneType',
    label: 'Stone Type',
    type: 'select',
    values: ['Natural', 'Clarity Enhanced', 'Color Enhanced', 'Lab Grown'],
  },
  {
    name: 'shape',
    label: 'Shape',
    type: 'select',
    values: [
      'Bead',
      'Bullet',
      'Calf',
      'Cushion',
      'Cut cornered square',
      'Drop',
      'Emerald',
      'Fancy',
      'Fantasy',
      'Half moon',
      'Heart',
      'Kite',
      'Lozenge',
      'Marquise',
      'Mixed shape',
      'Octagon',
      'Oval',
      'Pear',
      'Princess',
      'Radiant',
      'Rectangle',
      'Round',
      'Shield',
      'Square',
      'Star',
      'Tapered',
      'Trapezoid',
      'Triangle',
    ],
  },
  {
    name: 'caratWeight',
    label: 'Carat Weight (ct)',
    type: 'float', // Corrected type from 'numbe' to 'number'
    values: [],
  },
  {
    name: 'colorType',
    label: 'Color Type',
    type: 'select',
    values: ['Natural Colored', 'Natural White'],
  },
  {
    name: 'color',
    label: 'Color',
    type: 'multi-select', // Assuming selection from catalog
    values: ['D (colourless)', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N (tinted)', 'I-M (not certified)'], // Populate dynamically from Catalog
    dependentOn: { name: 'colorType', value: 'Natural White' }, // Optional: Add dependency
  },

  {
    name: 'fancyColorIntensity',
    label: 'Fancy Color Intensity of Main Stone',
    type: 'select', // Assuming predefined levels
    values: [
      'Faint',
      'Fancy',
      'Fancy dark',
      'Fancy deep',
      'Fancy intense',
      'Fancy light',
      'Fancy vivid',
      'Light',
      'Very light',
    ],
    dependentOn: { name: 'colorType', value: 'Natural Colored' }, // Optional: Add dependency
  },
  {
    name: 'fancyColorOvertone',
    label: 'Fancy Color Overtone of Main Stone',
    type: 'select',
    values: [
      'Blue',
      'Bluish',
      'Brown',
      'Brownish',
      'Green',
      'Greenish',
      'Grey',
      'Greyish',
      'No overtone',
      'Orange',
      'Orangy',
      'Pink',
      'Pinkish',
      'Purple',
      'Purplish',
      'Red',
      'Reddish',
      'Violet',
      'Violetish',
      'Yellow',
      'Yellowish',
    ],
    dependentOn: { name: 'colorType', value: 'Natural Colored' }, // Optional: Add dependency
    withPrevious: true, // Optional: Show with previous field
  },
  {
    name: 'fancyColorMainStone',
    label: 'Fancy Color of Main Stone',
    type: 'select',
    values: [
      'Black',
      'Blue',
      'Brown',
      'Green',
      'Grey',
      'Mixed brown',
      'Mixed colours',
      'Mixed orange',
      'Mixed pink',
      'Mixed yellow',
      'Orange',
      'Pink',
      'Purple',
      'Red',
      'Violet',
      'White',
      'Yellow',
    ],
    dependentOn: { name: 'colorType', value: 'Natural Colored' }, // Optional: Add dependency
    withPrevious: true, // Optional: Show with previous field
  },
  {
    name: 'clarityGrade',
    label: 'Diamond Clarity',
    type: 'multi-select',
    dependentOn: { name: 'mainStone', value: '*' },
    values: [
      'FL',
      'IF',
      'VVS1',
      'VVS2',
      'VS1',
      'VS2',
      'SI1',
      'SI2',
      'SI3',
      'I1',
      'I2',
      'I3',
      'Not specified in lab report',
    ],
  },
  {
    name: 'cutGrade',
    label: 'Cut Grade',
    type: 'text',
    values: ['Excellent', 'Fair', 'Good', 'Poor', 'Very good'],
  },
  {
    name: 'symmetry',
    label: 'Symmetry',
    type: 'text',
    values: ['Excellent', 'Fair', 'Good', 'Poor', 'Very good'],
  },
  {
    name: 'polish',
    label: 'Polish',
    type: 'text',
    values: ['Excellent', 'Fair', 'Good', 'Poor', 'Very good'],
  },
  {
    name: 'fluorescence',
    label: 'Fluorescence',
    type: 'text',
  },
  {
    name: 'certification',
    label: 'Certification',
    type: 'string', // Clarify if it's a document upload or selection
    ai_instruction: 'If the jewelry has multiple certifications, please include all of them',
  },
  {
    name: 'certificationNumber',
    label: 'Certification Number',
    type: 'string',
    ai_instruction: 'If the jewelry has multiple certifications, please include all of them',
    values: [],
  },
  {
    name: 'additionalInfo',
    label: 'Additional Information (Optional)',
    type: 'string',
    values: [],
  },
  {
    name: 'sku',
    label: 'SKU',
    type: 'string',
    values: [],
  },
];
