// src/components/auction/AuctionListPage/SearchAndToggleControls.tsx

import React from 'react';
import { Grid2, Button, TextField, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext'; // Adjust the path as needed

interface SearchAndToggleControlsProps {
  searchQuery: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onlyMyAuctions: boolean;
  onToggleOnlyMyAuctions: () => void;
  auctionContext: boolean;
}

const SearchAndToggleControls: React.FC<SearchAndToggleControlsProps> = ({
  searchQuery,
  onSearchChange,
  onlyMyAuctions,
  onToggleOnlyMyAuctions,
  auctionContext,
}) => {
  const navigate = useNavigate();
  const { user, isLoggedIn, loading } = useAuth();

  return (
    <Grid2 container spacing={2} alignItems="center">
      <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
        {/* Add Auction Button */}
        {!auctionContext && (
          <Button variant="contained" color="primary" onClick={() => navigate('/add-auction-item')} fullWidth>
            Add New Item
          </Button>
        )}
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
        {/* Only My Auctions Toggle */}
        {isLoggedIn && user?.role === 'Admin' && (
          <FormControlLabel
            control={<Switch checked={onlyMyAuctions} onChange={onToggleOnlyMyAuctions} color="primary" />}
            label="Only My Items"
          />
        )}
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
        {/* Search TextField */}
        <TextField label="Search Auctions" value={searchQuery} onChange={onSearchChange} fullWidth margin="normal" />
      </Grid2>
    </Grid2>
  );
};

export default SearchAndToggleControls;
