// src/components/auction/AuctionListPage/AuctionTable.tsx

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import AuctionRow from './AuctionRow';
import { format } from 'date-fns'; // Ensure you have date-fns installed

interface AuctionItem {
  _id: string;
  product: { title: string; description: string; customerSKU: string }; // Add this property
  currentBid: number;
  startingPrice: number;
  status: string;
  startDate: string;
  endDate: string;
  isHotListed: boolean;
  img: string;
}

interface AuctionTableProps {
  auctions: AuctionItem[];
  isLoading: boolean;
  sortOption: string;
  onSort: (
    field: 'currentBid' | 'endDate' | 'startDate' | 'title' | 'description' | 'status' | 'startingPrice' | 'customerSKU'
  ) => void;
  onDelete: (auctionId: string) => void;
  onToggleHotList: (auctionId: string) => void; // Added prop
  auctionContext: boolean;
}

const AuctionTable: React.FC<AuctionTableProps> = ({
  auctions,
  isLoading,
  sortOption,
  onSort,
  onDelete,
  onToggleHotList, // Destructure the new prop
  auctionContext,
}) => {
  const getSortDirection = (field: string) => {
    if (sortOption === `${field}Asc`) return 'asc';
    if (sortOption === `${field}Desc`) return 'desc';
    return 'asc';
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {/* Title with Sorting */}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'titleAsc' || sortOption === 'titleDesc'}
                direction={getSortDirection('title')}
                onClick={() => onSort('title')}
              >
                Title
              </TableSortLabel>
            </TableCell>

            {/* Description with Sorting */}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'descriptionAsc' || sortOption === 'descriptionDesc'}
                direction={getSortDirection('description')}
                onClick={() => onSort('description')}
              >
                Description
              </TableSortLabel>
            </TableCell>

            {/* customer SKU with sort*/}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'customerSKUAsc' || sortOption === 'customerSKUDesc'}
                direction={getSortDirection('customerSKU')}
                onClick={() => onSort('customerSKU')}
              >
                SKU
              </TableSortLabel>
            </TableCell>

            {/* Current Bid with Sorting */}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'currentBidAsc' || sortOption === 'currentBidDesc'}
                direction={getSortDirection('currentBid')}
                onClick={() => onSort('currentBid')}
              >
                Current Bid
              </TableSortLabel>
            </TableCell>

            {/* Starting Price with Sorting */}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'startingPriceAsc' || sortOption === 'startingPriceDesc'}
                direction={getSortDirection('startingPrice')}
                onClick={() => onSort('startingPrice')}
              >
                Starting Price
              </TableSortLabel>
            </TableCell>

            {/* Status with Sorting */}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'statusAsc' || sortOption === 'statusDesc'}
                direction={getSortDirection('status')}
                onClick={() => onSort('status')}
              >
                Status
              </TableSortLabel>
            </TableCell>

            {/* Start Date with Sorting */}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'timeToStart'}
                direction="asc" // Always 'asc' as per server
                onClick={() => onSort('startDate')}
              >
                Start Date
              </TableSortLabel>
            </TableCell>

            {/* End Date with Sorting */}
            <TableCell>
              <TableSortLabel
                active={sortOption === 'timeToClose'}
                direction="asc" // Always 'asc' as per server
                onClick={() => onSort('endDate')}
              >
                End Date
              </TableSortLabel>
            </TableCell>

            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <Typography>Loading...</Typography>
              </TableCell>
            </TableRow>
          ) : auctions.length === 0 ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <Typography>No items in the list.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            auctions.map((auction) => {
              // Format the dates without the year
              const formattedStartDate = format(new Date(auction.startDate), 'MMM dd');
              const formattedEndDate = format(new Date(auction.endDate), 'MMM dd');

              return (
                <AuctionRow
                  key={auction._id}
                  auction={{
                    ...auction,
                    customerSKU: auction.product.customerSKU, // Pass the new property
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    isHotListed: auction.isHotListed,
                  }}
                  onDelete={onDelete}
                  onToggleHotList={onToggleHotList} // Pass the handler
                />
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuctionTable;
