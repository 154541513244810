// src/components/TopSlideshow.tsx

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { AuctionItem as AuctionItemType } from '../models/interfaces';
import AuctionCard from './AuctionCard';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';
import './TopSlideshow.css';

interface TopSlideshowProps {
  items: AuctionItemType[];
}

const TopSlideshow: React.FC<TopSlideshowProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const transitionDuration = 1000; // 1 second for slide in/out
  const displayDuration = 5000; // 5 seconds display
  const isMobile = useMediaQuery('(max-width:767px)');

  // Clear existing timeout
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // Function to go to the next slide
  const nextSlide = useCallback(() => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  }, [currentIndex, items.length]);

  // Function to go to the previous slide
  const prevSlide = useCallback(() => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  }, [currentIndex, items.length]);

  // Function to go to a specific slide
  const goToSlide = useCallback(
    (index: number) => {
      if (index === currentIndex) return;
      setPrevIndex(currentIndex);
      setCurrentIndex(index);
    },
    [currentIndex]
  );

  // Setup automatic slide transitions
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      nextSlide();
    }, displayDuration);

    return () => {
      resetTimeout();
    };
  }, [currentIndex, nextSlide]);

  // Handle exiting slide reset
  useEffect(() => {
    if (prevIndex !== null) {
      const transitionTimeout = setTimeout(() => {
        setPrevIndex(null);
      }, transitionDuration);
      return () => clearTimeout(transitionTimeout);
    }
  }, [prevIndex, transitionDuration]);

  // Swipe handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        prevSlide();
      } else if (e.key === 'ArrowRight') {
        nextSlide();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [prevSlide, nextSlide]);

  return (
    <div className="top-slideshow-container">
      {!isMobile && (
        <img
          src="/site/background2.jpg"
          alt="Background"
          className="background-image"
          style={{
            position: 'absolute',
            top: 0,
            left: '30%',
            width: '30%',
            height: '100%',
            maxHeight: '800px',
            objectFit: 'none',
            objectPosition: 'top',
            overflow: 'hidden',
            zIndex: -1,
          }}
        />
      )}

      <Box
        className="top-slideshow-container-box"
        {...handlers}
        onMouseEnter={resetTimeout}
        onMouseLeave={() => {
          timeoutRef.current = setTimeout(() => {
            nextSlide();
          }, displayDuration);
        }}
      >
        {items.map((item, index) => {
          let className = 'slideshow-item';
          if (index === currentIndex) {
            className += ' active';
          } else if (index === prevIndex) {
            className += ' exit';
          }

          return (
            <Box key={item._id} className={className}>
              <AuctionCard item={item} />
            </Box>
          );
        })}

        {/* Navigation Controls */}
        <IconButton className="nav-button prev-button" onClick={prevSlide} aria-label="Previous Slide">
          <ArrowBackIos />
        </IconButton>
        <IconButton className="nav-button next-button" onClick={nextSlide} aria-label="Next Slide">
          <ArrowForwardIos />
        </IconButton>

        {/* Indicators */}
        <Box className="indicators" role="tablist">
          {items.map((_, idx) => (
            <Box
              key={idx}
              className={`indicator ${idx === currentIndex ? 'active' : ''}`}
              onClick={() => goToSlide(idx)}
              role="tab"
              aria-selected={idx === currentIndex}
              aria-label={`Go to slide ${idx + 1}`}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  goToSlide(idx);
                }
              }}
            />
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default TopSlideshow;
