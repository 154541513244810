import React from 'react';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface Auction {
  _id: string;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  status: string;
  auctionItems: string[];
}

interface AuctionRowProps {
  auction: Auction;
  onDelete: (auctionId: string) => void;
}

const AuctionRow: React.FC<AuctionRowProps> = ({ auction, onDelete }) => {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell>{auction.name}</TableCell>
      <TableCell>{auction.description}</TableCell>
      <TableCell>{new Date(auction.startTime).toLocaleString()}</TableCell>
      <TableCell>{new Date(auction.endTime).toLocaleString()}</TableCell>
      <TableCell>{auction.status}</TableCell>
      <TableCell>{auction.auctionItems.length}</TableCell>
      <TableCell align="right">
        <IconButton color="primary" onClick={() => navigate(`/auctions/edit/${auction._id}`)}>
          <EditIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => onDelete(auction._id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default AuctionRow;
