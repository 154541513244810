// src/components/UserMenu.tsx

import React, { useState, useRef, useEffect } from 'react';
import { Menu, MenuItem, Button, Divider, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './UserMenu.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface UserMenuProps {
  user: {
    displayName: string;
    role?: string; // role is now optional
  };
  onLogout: () => void;
  onApplyFilters: (filters: any) => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ user, onLogout, onApplyFilters }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const navigate = useNavigate();

  // Ref to store the timer ID for closing the menu
  const closeTimer = useRef<NodeJS.Timeout | null>(null);

  // Detect if the device supports touch
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  // Detect screen size
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md breakpoint is 960px

  // Helper function to truncate the display name
  const truncateDisplayName = (name: string) => {
    return name.length > 12 ? name.substring(0, 12) + '...' : name;
  };

  // Function to open the menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    // Prevent default behavior for keyboard events
    if ('preventDefault' in event) {
      event.preventDefault();
    }

    // Clear any existing close timers
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = null;
    }
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  // Function to close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to handle mouse entering the button
  const handleButtonMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (isTouchDevice) return; // Ignore hover on touch devices
    setIsButtonHovered(true);
    // Clear any existing close timers
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = null;
    }
    handleMenuOpen(event);
  };

  // Function to handle mouse leaving the button
  const handleButtonMouseLeave = () => {
    if (isTouchDevice) return; // Ignore hover on touch devices
    setIsButtonHovered(false);
    // If the mouse is not over the menu, start the close timer
    if (!isMenuHovered) {
      startCloseTimer();
    }
  };

  // Function to handle mouse entering the menu
  const handleMenuMouseEnter = () => {
    if (isTouchDevice) return; // Ignore hover on touch devices
    setIsMenuHovered(true);
    // Clear any existing close timers
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = null;
    }
  };

  // Function to handle mouse leaving the menu
  const handleMenuMouseLeave = () => {
    if (isTouchDevice) return; // Ignore hover on touch devices
    setIsMenuHovered(false);
    // If the mouse is not over the button, start the close timer
    if (!isButtonHovered) {
      startCloseTimer();
    }
  };

  // Function to start the close timer
  const startCloseTimer = () => {
    if (!closeTimer.current) {
      closeTimer.current = setTimeout(() => {
        handleMenuClose();
        closeTimer.current = null;
      }, 1000); // 1000ms = 1 second
    }
  };

  // Cleanup timer on component unmount
  useEffect(() => {
    return () => {
      if (closeTimer.current) {
        clearTimeout(closeTimer.current);
      }
    };
  }, []);

  // Helper function to render a MenuItem
  const renderMenuItem = (label: string, onClick: () => void, key: string, onlyOnMobile: boolean = false) => (
    <MenuItem
      key={key}
      className={onlyOnMobile ? 'mobile-only' : ''}
      sx={{
        fontSize: '14px',
        fontFamily: 'Muli, Arial, Helvetica, sans-serif',
        fontStyle: 'normal',
        lineHeight: '20px',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Ensures text doesn't collapse
        MozTextSizeAdjust: '100%',
        padding: { xs: 0.5, sm: 0.5, md: 1, lg: 1 },
      }}
      onClick={() => {
        onClick();
        handleMenuClose(); // Close menu after click
      }}
    >
      {label}
    </MenuItem>
  );

  // Define your menu items
  const menuItems = [
    {
      label: 'Settings Page',
      onClick: () => navigate('/settings'),
      key: 'settings',
    },
    // {
    //   label: 'Select Language',
    //   onClick: () => navigate('/language'),
    //   key: 'language',
    // },
    {
      label: 'Current Bids',
      onClick: () => {
        console.log('Current Bids clicked navigate /');
        navigate('/');
        onApplyFilters({ myBids: true });
      },
      key: 'current-bids',
    },
    {
      label: 'Favourites',
      onClick: () => {
        console.log('Favourites clicked navigate /');
        navigate('/');
        onApplyFilters({ favorites: true });
      },
      key: 'favourites',
    },
  ];

  // Additional items for 'Supplier' or 'Admin'
  if (user.role === 'Supplier' || user.role === 'Admin') {
    menuItems.push({
      label: 'Auction Items',
      onClick: () => navigate('/auctions'),
      key: 'auction-items',
    });
  }

  // Additional items for 'Admin'
  if (user.role === 'Admin') {
    menuItems.push(
      {
        label: 'Auctions',
        onClick: () => navigate('/auction-management'),
        key: 'auction-management',
      },
      {
        label: 'Suppliers',
        onClick: () => {
          navigate('/suppliers');
          onApplyFilters({});
        },
        key: 'suppliers',
      },
      {
        label: 'Users',
        onClick: () => {
          navigate('/users');
          onApplyFilters({});
        },
        key: 'users',
      }
    );
  }

  return (
    <div className="user-menu" style={{ display: 'inline-block' }}>
      <Button
        className="user-menu-button"
        onMouseEnter={!isTouchDevice ? handleButtonMouseEnter : undefined} // Open on hover for non-touch
        onMouseLeave={!isTouchDevice ? handleButtonMouseLeave : undefined} // Handle mouse leaving the button for non-touch
        onClick={isTouchDevice ? handleMenuOpen : undefined} // Open on click for touch devices
        startIcon={<AccountCircle />}
        style={{ textTransform: 'none' }}
        aria-controls={anchorEl ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
      >
        {isDesktop && (
          <span className="user-name">
            {user.displayName.length > 12 ? `${user.displayName.substring(0, 12)}...` : user.displayName}
          </span>
        )}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        classes={{ paper: 'user-menu-paper' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        // Disable auto focus to prevent focus issues on hover
        autoFocus={false}
        // Ensure the menu stays open when interacting with it
        disableAutoFocusItem
        // Add transition duration for smoothness (optional)
        transitionDuration={200}
        // Attach mouse event handlers to the Menu's Paper component
        PaperProps={{
          onMouseEnter: handleMenuMouseEnter,
          onMouseLeave: handleMenuMouseLeave,
        }}
      >
        {/* On mobile, display the username as the first menu item */}
        {!isDesktop && user && (
          <>
            {renderMenuItem(
              user.displayName.length > 12 ? `${user.displayName.substring(0, 12)}...` : user.displayName,
              () => {},
              'user-name-mobile',
              true
            )}
            <Divider />
          </>
        )}
        {/* Loop through and render menu items */}
        {menuItems.map((item) => renderMenuItem(item.label, item.onClick, item.key))}
        <Divider />
        {renderMenuItem('Logout', onLogout, 'logout')}
      </Menu>
    </div>
  );
};

export default UserMenu;
