// src/components/onboarding/OnboardingStepper.tsx

import React, { useEffect, useState } from 'react';
import {
  Stepper,
  Step,
  StepButton,
  Box,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Button,
} from '@mui/material';
import Step1PersonalInfo from './Step1PersonalInfo';
import Step2AddressInfo from './Step2AddressInfo';
import Step3PaymentMethod from './Step3PaymentMethod';
import Step4MobileVerification from './Step4MobileVerification';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UpdateUserProfileDto } from './dto/UpdateUserProfileDto';
import { OnboardingPersonalInfo, OnboardingAddressInfo, MobileVerificationDto } from './dto/OnboardingDto';
import apiClient from '../apiClient'; // Import the API client
import { MobileVerificationProvider } from './MobileVerificationContext'; // Import the MobileVerificationProvider

const steps = ['Personal Information', 'Address Information', 'Payment Method', 'Mobile Verification'];

interface FormData {
  personalInfo: OnboardingPersonalInfo;
  addressInfo: OnboardingAddressInfo;
  paymentMethod: string;
  billingSameAsShipping: boolean;
  mobileVerification: MobileVerificationDto;
  hasCompletedOnboarding: boolean;
}

const OnboardingStepper: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { updateUserProfile } = useAuth(); // Ensure this is implemented in AuthContext
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState<boolean>(false); // Global loading state
  const [error, setError] = useState<string>(''); // Global error state

  const [formData, setFormData] = useState<FormData>({
    personalInfo: {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      countryCode: '+1', // Default to USA
    },
    addressInfo: {
      street: '',
      aptSuite: '',
      company: '',
      country: '',
      state: '',
      city: '',
      zip: '',
    },
    paymentMethod: '',
    billingSameAsShipping: true,
    mobileVerification: {
      verificationCode: '',
      mobileNumber: '',
      countryCode: '+1',
    },
    hasCompletedOnboarding: false,
  });

  // Mapping steps to their respective API endpoints
  const stepApiEndpoints: { [key: number]: string } = {
    0: '/user/onboarding/personal-info',
    1: '/user/onboarding/address-info',
    2: '/user/onboarding/payment-method',
    // 3: '/user/verify-mobile', // Handled separately in Step4
  };

  const handleNext = async (stepData: any) => {
    console.log(`Handling next for step ${activeStep} with data:`, stepData);
    setLoading(true);
    setError('');

    try {
      if (activeStep === 3) {
        // Step 4: Mobile Verification
        // Update mobileVerification data
        setFormData((prev) => ({
          ...prev,
          mobileVerification: stepData as MobileVerificationDto,
        }));

        // Proceed to finalize onboarding
        setActiveStep((prev) => prev + 1);
        await finalizeOnboarding();
      } else {
        // Handle steps 0,1,2 as before
        const apiEndpoint = stepApiEndpoints[activeStep];
        let payload: any = {};

        switch (activeStep) {
          case 0:
            payload = stepData as OnboardingPersonalInfo;
            break;
          case 1:
            payload = stepData as OnboardingAddressInfo;
            break;
          case 2:
            payload = {
              paymentMethod: stepData.paymentMethod,
              billingSameAsShipping: stepData.billingSameAsShipping,
            };
            break;
          default:
            break;
        }

        const response = await apiClient.post(apiEndpoint, payload);
        console.log(`API response for step ${activeStep}:`, response.data);

        setFormData((prev) => {
          switch (activeStep) {
            case 0:
              return { ...prev, personalInfo: stepData };
            case 1:
              return { ...prev, addressInfo: stepData };
            case 2:
              return {
                ...prev,
                paymentMethod: stepData.paymentMethod,
                billingSameAsShipping: stepData.billingSameAsShipping,
              };
            default:
              return prev;
          }
        });

        if (activeStep < steps.length - 1) {
          setActiveStep((prev) => prev + 1);
        } else {
          await finalizeOnboarding();
        }
      }
    } catch (err: any) {
      console.error(`Error saving data for step ${activeStep}:`, err);
      setError(err.response?.data?.message || 'An error occurred while saving your data.');
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = async () => {
    console.log(`Skipping step ${activeStep}`);
    // Optionally, you can mark steps as skipped or handle accordingly
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    } else {
      await finalizeOnboarding();
    }
  };

  const finalizeOnboarding = async () => {
    setLoading(true);
    setError('');
    try {
      const payload: UpdateUserProfileDto = {
        hasCompletedOnboarding: true,
        personalInfo: {
          firstName: formData.personalInfo.firstName,
          lastName: formData.personalInfo.lastName,
          mobileNumber: formData.mobileVerification.mobileNumber || '',
          countryCode: formData.mobileVerification.countryCode || '+1',
        },
        addressInfo: {
          street: formData.addressInfo.street,
          country: formData.addressInfo.country,
          state: formData.addressInfo.state,
          city: formData.addressInfo.city,
          zip: formData.addressInfo.zip,
          aptSuite: formData.addressInfo.aptSuite,
          company: formData.addressInfo.company,
        },
        paymentMethod: formData.paymentMethod,
        billingSameAsShipping: formData.billingSameAsShipping,
        // Do not include 'mobileVerification' in the payload
      };

      const response = await apiClient.post('/user/onboarding/complete', payload);
      console.log('Finalizing onboarding:', response.data);

      // Optionally, update AuthContext or local storage
      updateUserProfile(response.data); // Assuming updateUserProfile updates the user state

      navigate('/'); // Redirect to home or desired page
      window.location.reload(); // Refresh to reflect updated user state
    } catch (err: any) {
      console.error('Error finalizing onboarding:', err);
      setError(err.response?.data?.message || 'An error occurred while finalizing onboarding.');
    } finally {
      setLoading(false);
    }
  };

  const handleStep = (step: number) => () => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Step1PersonalInfo onNext={handleNext} onSkip={handleSkip} initialData={formData.personalInfo} />;
      case 1:
        return <Step2AddressInfo onNext={handleNext} onSkip={handleSkip} initialData={formData.addressInfo} />;
      case 2:
        return (
          <Step3PaymentMethod
            onNext={handleNext}
            onSkip={handleSkip}
            initialData={{
              paymentMethod: formData.paymentMethod,
              billingSameAsShipping: formData.billingSameAsShipping,
            }}
          />
        );
      case 3:
        return (
          <Step4MobileVerification
            onNext={handleNext}
            onSkip={handleSkip}
            initialData={formData.mobileVerification}
            personalInfo={{
              firstName: formData.personalInfo.firstName,
              lastName: formData.personalInfo.lastName,
              mobileNumber: formData.mobileVerification.mobileNumber || '',
              countryCode: formData.mobileVerification.countryCode || '+1',
            }}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  useEffect(() => {
    const fetchOnboardingData = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get('/user/onboarding/data');
        const data = response.data;

        setFormData({
          personalInfo: {
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            mobileNumber: data.phoneNumber || '',
            countryCode: data.countryCode || '+1',
          },
          addressInfo: {
            street: data.billingAddress?.street || '',
            aptSuite: data.billingAddress?.aptSuite || '',
            company: data.billingAddress?.company || '',
            country: data.billingAddress?.country || '',
            state: data.billingAddress?.state || '',
            city: data.billingAddress?.city || '',
            zip: data.billingAddress?.zip || '',
          },
          paymentMethod: data.paymentMethod || '',
          billingSameAsShipping: data.billingSameAsShipping ?? true,
          mobileVerification: {
            verificationCode: '',
            mobileNumber: data.phoneNumber || '',
            countryCode: data.countryCode || '+1',
          },
          hasCompletedOnboarding: data.hasCompletedOnboarding || false,
        });
        setActiveStep(0);

        // Optionally, set activeStep based on which fields are missing
        // if (!data.firstName || !data.lastName) {
        //   setActiveStep(0);
        // } else if (!data.billingAddress?.street) {
        //   setActiveStep(1);
        // } else if (!data.paymentMethod) {
        //   setActiveStep(2);
        // } else if (!data.mobileVerified) {
        //   setActiveStep(3);
        // }
      } catch (err: any) {
        console.error('Error fetching onboarding data:', err);
        setError('Failed to load your data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchOnboardingData();
  }, []);

  return (
    <MobileVerificationProvider>
      <Box
        sx={{
          backgroundImage: `url('/background1.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              mt: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              boxShadow: 3,
              p: 4,
              borderRadius: 2,
              maxWidth: 400,
              mx: isMobile ? 'auto' : 'unset',
              ml: isMobile ? 'auto' : 4,
              mr: isMobile ? 'auto' : 'unset',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              height: 600,
              overflowY: 'auto',
            }}
          >
            <Stepper activeStep={activeStep} alternativeLabel nonLinear sx={{ width: '100%' }}>
              {steps.map((label, index) => (
                <Step key={label} completed={index < activeStep}>
                  <StepButton onClick={handleStep(index)} disabled={index > activeStep}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Box sx={{ mt: 5, width: '100%' }}>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </Box>
              ) : activeStep === steps.length ? (
                <Typography>All steps completed - you&apos;re finished</Typography>
              ) : (
                getStepContent(activeStep)
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </MobileVerificationProvider>
  );
};

export default OnboardingStepper;
