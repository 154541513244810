import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
import AuctionRow from './AuctionRow';

interface Auction {
  _id: string;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  status: string;
  auctionItems: string[];
}

interface AuctionTableProps {
  auctions: Auction[];
  onDelete: (auctionId: string) => void;
}

const AuctionTable: React.FC<AuctionTableProps> = ({ auctions, onDelete }) => {
  if (auctions.length === 0) {
    return (
      <Typography variant="body1" align="center" style={{ padding: '20px' }}>
        No auctions available.
      </Typography>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Items</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auctions.map((auction) => (
            <AuctionRow key={auction._id} auction={auction} onDelete={onDelete} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuctionTable;
