// src/pages/AuctionItemPage.tsx

import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Dialog,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Alert,
  useMediaQuery,
  Grid,
  Button,
} from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { useParams, useLocation } from 'react-router-dom';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { useCookies } from 'react-cookie';
import labels_en from '../localization/labels_en.json';
import { ProductTypes, Product, AuctionItem, isDiamond, isGemstone, isWatch, isJewelry } from '../models/interfaces';
import AuctionProgressSidebar from '../components/AuctionProgressSidebar';
import CountdownTimer from '../components/CountdownTimer';
import { useWebSocket } from '../contexts/WebSocketContext';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from '../contexts/AuthContext';
import apiClient from '../components/apiClient'; // Import your Axios instance
import { handleAxiosError } from '../utils/handleAxiosError'; // Import the utility function
import AuctionItemTrailer from '../components/auction/AuctionItemTrailer';

type ObjectType = 'diamond' | 'gemstone' | 'watch' | 'jewelry';

interface Labels {
  [key: string]: { [key: string]: string };
}

const labels: Labels = labels_en;

interface AuctionItemPageProps {
  onApplyFilters: (filters: any) => void;
  onChatContext: (chatContext: any) => void;
}

const AuctionItemPage: React.FC<AuctionItemPageProps> = ({ onApplyFilters, onChatContext }) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [cookies] = useCookies(['blinglong']); // Access cookies
  const [item, setItem] = useState<AuctionItem | null>(null);
  const [bidAmount, setBidAmount] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [product, setProduct] = useState<Product>({
    productType: 'Unknown',
    title: '',
    description: '',
    category: '',
  });
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { socket, isConnected } = useWebSocket(); // Access socket and connection status
  const { requireAuth, isLoggedIn } = useAuth(); // Get Auth0 functions
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const isMobile = useMediaQuery('(max-width:767px)');

  const updateChatContext = (data: any) => {
    let partialData = { ...data };
    if ('link' in partialData) partialData['link'] = undefined;
    if ('images' in partialData) partialData['images'] = undefined;
    if ('img' in partialData) partialData['img'] = undefined;
    if ('uploadedBy' in partialData) partialData['uploadedBy'] = undefined;

    onChatContext(partialData);
  };

  useEffect(() => {
    console.log('AuctionItemPage useEffect: Socket:', socket, 'isConnected:', isConnected);
    // Initial fetch of auction item data
    const fetchAuctionItem = async () => {
      try {
        const response = await apiClient.get(`/auction-items/${id}`);
        setItem(response.data);
        updateChatContext(response.data);
      } catch (error: any) {
        console.error(error);
        const message = handleAxiosError(error) || 'Failed to fetch auction item.';
        enqueueSnackbar(message, { variant: 'error' });
      }
    };

    fetchAuctionItem();
  }, [id]);

  // Scroll to top if scrollToTop state is true
  useEffect(() => {
    if (location.state && (location.state as any).scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location.state]);

  useEffect(() => {
    if (item) {
      if (item.quickBids && item.quickBids.length > 0) {
        setBidAmount(item.quickBids[0]);
      } else {
        setBidAmount(item.currentBid + 1);
      }
      setProduct(item.product);
    }
  }, [item]);

  useEffect(() => {
    if (socket && isConnected) {
      const handlePriceUpdate = (data: { auctionId: string; newPrice: number }) => {
        console.log('AuctionItemPage: Received priceUpdated event:', data);
        if (data.auctionId === id) {
          // Update the item state directly with the new price
          setItem((prevItem) => (prevItem ? { ...prevItem, currentBid: data.newPrice } : prevItem));
        }
      };

      console.log('AuctionItemPage: Socket is connected, setting up listeners');
      socket.on('priceUpdated', handlePriceUpdate);

      // Cleanup the listener on unmount
      return () => {
        socket.off('priceUpdated', handlePriceUpdate);
      };
    }
  }, [socket, isConnected, id]);

  if (!item) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  // Helper function to render a product's attribute dynamically
  const renderAttribute = (attribute: string, value: any, objectType: ObjectType) => {
    const label = labels[objectType][attribute] || attribute;
    if (
      value &&
      attribute !== 'description' &&
      attribute !== 'title' &&
      attribute !== 'type' &&
      attribute !== 'category' &&
      attribute !== '_id' &&
      attribute !== 'productType' &&
      attribute !== 'supplierSitePreview'
    ) {
      return (
        <Typography variant="body1" key={attribute} sx={{ marginTop: '8px' }}>
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {label}:
          </Box>
          <Box component="span" sx={{ marginLeft: '5px', color: 'success.main' }}>
            {typeof value === 'object' && value !== null ? (value as any).join(', ') : value}
          </Box>
        </Typography>
      );
    }
    return null;
  };

  // Object-type-based rendering
  const renderProductDetails = (objectType: ObjectType) => {
    return (
      <Grid2 container size={12} spacing={10} className="product-details-grid">
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Card className="product-details-card">
            <CardContent>
              <Typography variant="h5" sx={{ marginBottom: '16px' }}>
                {product.productType || ''} Details
              </Typography>
              {Object.entries(product)
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                .map(([key, value]) => renderAttribute(key, value, objectType))}
            </CardContent>
          </Card>
        </Grid2>
        {product.supplierSitePreview && (
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Card sx={{ padding: '16px' }}>
              <Typography variant="h5" sx={{ marginBottom: '16px' }}>
                Supplier Preview:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <iframe
                  src={product.supplierSitePreview}
                  title="Supplier Site Preview"
                  width={isMobile ? '100%' : '800px'}
                  height={isMobile ? '350px' : '800px'}
                  loading="lazy"
                  style={{ border: 'none', borderRadius: '8px' }}
                />
              </Box>
            </Card>
          </Grid2>
        )}
      </Grid2>
    );
  };

  const placeBidAction = async () => {
    setLoading(true);
    setError(null);
    try {
      await apiClient.post(
        `/auction-items/${id}/bid`,
        {
          amount: bidAmount,
        },
        { withCredentials: true }
      );

      // Fetch the updated auction item
      const response = await apiClient.get(`/auction-items/${id}`);
      setItem(response.data);

      // Notify the user of the successful bid
      enqueueSnackbar('Bid placed successfully!', { variant: 'success' });
    } catch (err: any) {
      const message = handleAxiosError(err) || 'Failed to place bid. Please try again.';
      setError(message);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const placeBid = async () => {
    try {
      await requireAuth(placeBidAction);
    } catch (error: any) {
      console.error(error);
      const message = handleAxiosError(error) || 'You must be logged in to perform this action.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const setMaxBidAction = async () => {
    try {
      await apiClient.post(
        `/auction-items/${id}/max-bid`,
        {
          amount: bidAmount,
        },
        { withCredentials: true }
      );

      // Optionally, fetch the updated auction item
      const response = await apiClient.get(`/auction-items/${id}`);
      setItem(response.data);
      enqueueSnackbar('Max bid set successfully!', { variant: 'success' });
    } catch (error: any) {
      console.error(error);
      const message = handleAxiosError(error) || 'Failed to set max bid. Please try again.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const setMaxBid = async () => {
    try {
      await requireAuth(setMaxBidAction);
    } catch (error: any) {
      console.error(error);
      const message = handleAxiosError(error) || 'You must be logged in to perform this action.';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Box sx={{ padding: { xs: '16px', md: '32px' } }}>
        <Typography variant="body1" sx={{ marginBottom: '8px' }}>
          Item #{item.auctionId}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            fontSize: { xs: '24px', md: '40px' },
            fontWeight: 400,
            lineHeight: '1.2',
            textAlign: 'left',
            color: '#0B051F',
            marginBottom: '16px',
          }}
        >
          {product.title}
        </Typography>

        <Container maxWidth="lg">
          <Grid2 container spacing={4}>
            {/* Top Section: Main Image and Auction Sidebar */}
            <Grid2
              container
              spacing={2}
              className="top-section"
              size={{ xs: 12 }}
              direction={{ sm: 'column', xs: 'column', md: 'row' }}
            >
              {/* Main Image and Thumbnails */}
              <Grid2 size={{ xs: 12, md: 7 }} className="main-image-container">
                <Box>
                  <Box
                    component="img"
                    src={item.img}
                    alt={product.title}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      cursor: 'pointer',
                      objectFit: 'cover',
                      borderRadius: '8px',
                    }}
                    onClick={() => setSelectedImage(item.img)}
                  />
                  {/* Thumbnails */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      marginTop: '10px',
                      overflowX: 'auto',
                    }}
                  >
                    {item.images.map((image, index) => (
                      <Box
                        key={index}
                        component="img"
                        src={image}
                        alt={`${product.title} image ${index + 1}`}
                        sx={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          transition: 'transform 0.2s',
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                        onClick={() => setSelectedImage(image)}
                      />
                    ))}
                  </Box>
                </Box>
              </Grid2>
              {/* Auction Sidebar */}
              <Grid2 size={{ xs: 12, md: 5 }} className="auction-sidebar-area">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '20px',
                    }}
                  >
                    <Typography variant="h6">Closes in:</Typography>
                    <CountdownTimer endDate={item.endDate} />
                  </Box>
                  <AuctionProgressSidebar
                    item={item}
                    bidAmount={bidAmount}
                    setBidAmount={setBidAmount}
                    placeBid={placeBid}
                    setMaxBid={setMaxBid}
                    onApplyFilters={onApplyFilters}
                  />
                </Box>
              </Grid2>
            </Grid2>
            {/* Product Details */}
            <Grid2 size={{ xs: 12 }} className="product-details-section">
              <Box sx={{ marginTop: '30px' }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: { xs: '24px', md: '32px' },
                    fontWeight: 500,
                    marginBottom: '16px',
                  }}
                >
                  {product.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '16px', md: '18px' },
                    marginBottom: '16px',
                  }}
                >
                  {product.description}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '16px', md: '18px' },
                    marginBottom: '16px',
                  }}
                >
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Category:
                  </Box>{' '}
                  {product.category}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '16px', md: '18px' },
                    marginBottom: '16px',
                  }}
                >
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Ends:
                  </Box>{' '}
                  {new Date(item.endDate).toLocaleString()}
                </Typography>
                {/* Dynamically render product attributes based on object type */}
                {product.productType && isDiamond(product) && renderProductDetails('diamond')}
                {product.productType && isGemstone(product) && renderProductDetails('gemstone')}
                {product.productType && isWatch(product) && renderProductDetails('watch')}
                {product.productType && isJewelry(product) && renderProductDetails('jewelry')}
              </Box>
            </Grid2>
            {/* Popup for image zoom */}
            <Dialog
              open={!!selectedImage}
              onClose={() => setSelectedImage(null)}
              maxWidth="xl"
              sx={{
                '& .MuiDialog-paper': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
            >
              {selectedImage && (
                <InnerImageZoom
                  src={selectedImage}
                  zoomSrc={selectedImage}
                  zoomType="click"
                  imgAttributes={{ alt: product.title }}
                  moveType="pan"
                  zoomScale={3}
                  hasSpacer={true}
                />
              )}
            </Dialog>
          </Grid2>
        </Container>

        <AuctionItemTrailer currentItem={item} />

        {/* Success Snackbar */}
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        {/* Error Snackbar */}
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default AuctionItemPage;
