// src/components/login/SignUpPage.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Alert,
  useTheme,
  useMediaQuery,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  InputAdornment,
  LinearProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SignUpPage: React.FC = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  // State to track password validation
  const [passwordErrors, setPasswordErrors] = useState<{
    length: boolean;
    number: boolean;
    lowercase: boolean;
    uppercase: boolean;
    specialChar: boolean;
  }>({
    length: false,
    number: false,
    lowercase: false,
    uppercase: false,
    specialChar: false,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Define the special characters set
  const specialCharacters = `^$*.[]{}()?-"!@#%&/\\,><':;|_~\`+=`;

  // Regular expressions for validation
  const regex = {
    length: /^.{8,}$/,
    number: /[0-9]/,
    lowercase: /[a-z]/,
    uppercase: /[A-Z]/,
    specialChar: new RegExp(`[${specialCharacters.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}]`),
    spaceChar: /(?<!^) (?!.{0,1}$)/, // Space not at the start or end
  };

  // Validation function
  const validatePassword = useCallback(
    (pwd: string) => {
      const hasLength = regex.length.test(pwd);
      const hasNumber = regex.number.test(pwd);
      const hasLowercase = regex.lowercase.test(pwd);
      const hasUppercase = regex.uppercase.test(pwd);
      const hasSpecialChar = regex.specialChar.test(pwd);
      const hasValidSpace = regex.spaceChar.test(pwd);

      // Special character condition: Either specialChar is true or spaceChar is true
      const specialOrSpace = hasSpecialChar || hasValidSpace;

      setPasswordErrors({
        length: hasLength,
        number: hasNumber,
        lowercase: hasLowercase,
        uppercase: hasUppercase,
        specialChar: specialOrSpace,
      });

      return hasLength && hasNumber && hasLowercase && hasUppercase && specialOrSpace;
    },
    [regex]
  );

  // Handle password input change
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pwd = e.target.value;
    setPassword(pwd);
    validatePassword(pwd);
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Validate password before submitting
    const isPasswordValid = validatePassword(password);
    if (!isPasswordValid) {
      setError('Please ensure your password meets all the requirements.');
      return;
    }

    setLoading(true);
    try {
      await signUp(email, password, {
        email,
      });
      setSuccess('Sign-up successful! Please check your email to confirm your account.');
      // Pass email and password to the confirmation page
      navigate('/auth/confirm-signup', { state: { email, password } });
    } catch (err: any) {
      setError(err.message || 'Sign-up failed');
    } finally {
      setLoading(false);
    }
  };

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Prevent mouse down event on visibility icon
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Calculate password strength for the progress bar
  const calculatePasswordStrength = () => {
    const totalRequirements = Object.keys(passwordErrors).length;
    const metRequirements = Object.values(passwordErrors).filter(Boolean).length;
    return (metRequirements / totalRequirements) * 100;
  };

  // Helper component to display validation status
  const ValidationItem: React.FC<{ valid: boolean; text: string }> = ({ valid, text }) => (
    <ListItem sx={{ py: 0.5 }}>
      {valid ? (
        <ListItemIcon sx={{ minWidth: '24px' }}>
          <CheckCircleIcon color="success" fontSize="small" aria-label="Requirement met" />
        </ListItemIcon>
      ) : (
        <ListItemIcon sx={{ minWidth: '24px' }}>
          {/* Placeholder to align text without showing an icon */}
          <Box width="24px" />
        </ListItemIcon>
      )}
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          variant: 'caption', // Reduced font size
          color: valid ? 'success.main' : 'text.primary',
        }}
      />
    </ListItem>
  );

  return (
    <Box
      sx={{
        backgroundImage: `url('/background1.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm">
        <Box sx={{ minHeight: '30px' }} />
        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: 3,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            mx: isMobile ? 'auto' : 'unset',
            ml: isMobile ? 'auto' : 4,
            mr: isMobile ? 'auto' : 'unset',
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
              {success}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-label="Email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              value={password}
              onChange={handlePasswordChange}
              helperText="Password must meet the requirements below."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              aria-describedby="password-requirements"
            />
            {/* Password Strength Progress Bar */}
            <Box sx={{ mt: 1, width: '100%' }}>
              <LinearProgress
                variant="determinate"
                value={calculatePasswordStrength()}
                sx={{
                  height: '8px',
                  borderRadius: '4px',
                  backgroundColor: theme.palette.grey[300],
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: calculatePasswordStrength() === 100 ? 'success.main' : theme.palette.primary.main,
                  },
                }}
                aria-label="Password strength"
              />
              <Typography variant="caption" color="text.secondary">
                Password Strength: {Math.round(calculatePasswordStrength())}%
              </Typography>
            </Box>
            <FormHelperText
              sx={{
                mt: 1,
                fontSize: '0.75rem', // Reduced font size
                color: 'text.secondary',
              }}
              id="password-requirements"
            >
              <List dense>
                <ValidationItem valid={passwordErrors.length} text="At least 8 characters" />
                <ValidationItem valid={passwordErrors.number} text="Contains at least 1 number" />
                <ValidationItem valid={passwordErrors.lowercase} text="Contains at least 1 lowercase letter" />
                <ValidationItem valid={passwordErrors.uppercase} text="Contains at least 1 uppercase letter" />
                <ValidationItem
                  valid={passwordErrors.specialChar}
                  text={`Contains at least 1 special character (${specialCharacters}) or a non-leading, non-trailing space`}
                />
              </List>
            </FormHelperText>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
              aria-label="Sign Up"
            >
              {loading ? 'Signing up...' : 'Sign Up'}
            </Button>
            <Typography variant="body2" align="center">
              Already have an account? <Link to="/auth/login">Login</Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SignUpPage;
