// src/components/auction/AuctionItemTrailer.tsx

import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import HorizontalAuctionList from '../HorizontalAuctionList';
import { AuctionItem as AuctionItemType } from '../../models/interfaces';
import apiClient from '../apiClient';
import { useSnackbar } from 'notistack';
import { handleAxiosError } from '../../utils/handleAxiosError';

interface AuctionItemTrailerProps {
  currentItem: AuctionItemType;
}

const AuctionItemTrailer: React.FC<AuctionItemTrailerProps> = ({ currentItem }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [similarItems, setSimilarItems] = useState<AuctionItemType[]>([]);
  const [moreFromSupplierItems, setMoreFromSupplierItems] = useState<AuctionItemType[]>([]);
  const [similarLoading, setSimilarLoading] = useState<boolean>(true);
  const [moreFromSupplierLoading, setMoreFromSupplierLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSimilarItems = async () => {
      try {
        const response = await apiClient.get('/auction-items/list', {
          params: {
            q: currentItem.product.title, // Lucene query for similar titles
            page: 1, // Page number
            limit: 10, // Adjust as needed
          },
        });
        setSimilarItems(response.data.auctions);
      } catch (error: any) {
        console.error('Error fetching similar items:', error);
        const message = handleAxiosError(error) || 'Failed to fetch similar items.';
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setSimilarLoading(false);
      }
    };

    const fetchMoreFromSupplier = async () => {
      try {
        const response = await apiClient.get('/auction-items/list', {
          params: {
            supplier: currentItem.supplier?._id, // Filter by supplier
            limit: 10, // Adjust as needed
            page: 1, // Page number
          },
        });
        setMoreFromSupplierItems(response.data.auctions);
      } catch (error: any) {
        console.error('Error fetching more from supplier:', error);
        const message = handleAxiosError(error) || 'Failed to fetch items from this supplier.';
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setMoreFromSupplierLoading(false);
      }
    };

    // Reset states before fetching new data
    setSimilarLoading(true);
    setMoreFromSupplierLoading(true);
    setSimilarItems([]);
    setMoreFromSupplierItems([]);

    fetchSimilarItems();
    fetchMoreFromSupplier();
  }, [currentItem, enqueueSnackbar]);

  if (similarLoading || moreFromSupplierLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box my={4}>
      {/* Similar Items Section */}
      {similarItems.length > 0 && (
        <HorizontalAuctionList
          title="Similar Items"
          titleLocation={{ margin: { xs: '0px 0px 0px 70%', md: '0px 0px 0px 0px' } }}
          items={similarItems}
          img="/site/row2.png" // Replace with actual image path
          titleColor="black"
        />
      )}

      {/* More from This Supplier Section */}
      {moreFromSupplierItems.length > 0 && (
        <HorizontalAuctionList
          title={`More From: <br />&nbsp  ${currentItem.supplier?.name}`}
          items={moreFromSupplierItems}
          img="/site/row5.png" // Replace with actual image path
          titleColor="black"
        />
      )}
    </Box>
  );
};

export default AuctionItemTrailer;
