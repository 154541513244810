// src/components/add-auction/AddAuctionPage.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, Button, CircularProgress, Box, SelectChangeEvent } from '@mui/material';
import imageCompression from 'browser-image-compression';

import { AuctionItem, CurrentUser, ProductTypes, ImageItem, Auction } from '../../models/interfaces';

import ImageUploadSection from './ImageUploadSection';
import AuctionDetailsForm from './AuctionDetailsForm';

// Import field definitions
import { diamondFields } from './fields/diamondFields';
import { gemstoneFields } from './fields/gemstoneFields';
import { watchFields } from './fields/watchFields';
import { jewelryFields } from './fields/jewelryFields';
import AnalyzeModal from './AnalyzeModal'; // Import the new component
import { calculateTitleForJewelry } from './fields/jewelryFields';
import { calculateTitleForDiamond } from './fields/diamondFields';
import { calculateTitleForGemstone } from './fields/gemstoneFields';
import { calculateTitleForWatch } from './fields/watchFields';

// Import the new RenderFields component (if needed elsewhere)
import RenderFields, { FieldDefinition } from './renderFields';

import './styles/AddAuctionPage.css';
import { useConfirmation } from '../../contexts/ConfirmationContext';
import { NumericFormatChangeEvent } from './renderFields';

import apiClient from '../apiClient';

const MAX_IMAGE_COUNT = 10; // Maximum number of images allowed

interface AddAuctionPageProps {
  user: CurrentUser;
}

const AddAuctionPage: React.FC<AddAuctionPageProps> = ({ user }) => {
  const { id } = useParams<{ id: string }>(); // Get the auction ID from URL params
  const [isEditMode, setIsEditMode] = useState(Boolean(id));
  const navigate = useNavigate();
  const [analyzeModalOpen, setAnalyzeModalOpen] = useState<boolean>(false);

  const [auctionItem, setAuctionItem] = useState<AuctionItem>({
    _id: '',
    bids: [],
    currentBid: 0,
    status: 'Draft',
    startingPrice: 1,
    reservePrice: 0,
    buyItNowPrice: 0,
    sellerUpraisal: 0,
    category: '',
    startDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString(), // Default in 1 day
    endDate: new Date(Date.now() + 8 * 24 * 60 * 60 * 1000).toISOString(), // Default to 7 days from now
    auction: {
      _id: '',
      name: '',
    },
    expertEstimateMin: 100,
    expertEstimateMax: 200,
    product: {
      productType: '',
      title: '',
      description: '',
      customerSKU: '',
      // ... add other product-specific fields as needed
    } as ProductTypes,
    img: '',
    images: [],
    quickBids: [],
    maxBid: 0,
    isFavorite: false,
    supplier: {} as any,
    auctionId: 0,
    supplierInternals: '',
  });

  const [selectedProductType, setSelectedProductType] = useState<string>('');

  // Unified images state
  const [images, setImages] = useState<ImageItem[]>([]); // Combined list of existing and new images
  const [coverImage, setCoverImage] = useState<ImageItem | null>(null);

  const [loading, setLoading] = useState<boolean>(false); // Loading state for fetching data
  const [error, setError] = useState<string>(''); // Error state for fetching data
  const [submitting, setSubmitting] = useState<boolean>(false); // Submitting state
  const { confirm } = useConfirmation();
  const [auctionList, setAuctionList] = useState<Auction[]>([]);

  // Mapping from productType to fields and calculateTitle function
  const productTypeFieldsMap: {
    [key: string]: {
      fields: FieldDefinition[];
      calculateTitle: (productFields: any) => string;
    };
  } = {
    Jewelry: {
      fields: jewelryFields,
      calculateTitle: calculateTitleForJewelry,
    },
    Diamond: {
      fields: diamondFields,
      calculateTitle: calculateTitleForDiamond,
    },
    Gemstone: {
      fields: gemstoneFields,
      calculateTitle: calculateTitleForGemstone,
    },
    Watch: {
      fields: watchFields,
      calculateTitle: calculateTitleForWatch,
    },
    // ... other product types ...
  };

  // Handler to remove an image
  const handleRemoveImage = (index: number) => {
    setImages((prev) => {
      const imageToRemove = prev[index];
      if (!imageToRemove.existing) {
        URL.revokeObjectURL(imageToRemove.src);
      }
      return prev.filter((_, i) => i !== index);
    });
  };

  // Handler for product type selection
  const handleProductTypeSelection = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as string;
    setSelectedProductType(value);

    setAuctionItem((prev) => {
      const updatedProduct = {
        ...prev.product,
        productType: value,
      };

      // Get the calculateTitle function based on the new productType
      const calculateTitleFn = productTypeFieldsMap[value]?.calculateTitle;

      const reservePriceStr = Number(prev.reservePrice) > 0 ? '' : 'NoReservePrice ';
      // Calculate the new title
      const calculatedTitle = calculateTitleFn ? calculateTitleFn(updatedProduct) : '';

      return {
        ...prev,
        product: {
          ...updatedProduct,
          title: reservePriceStr + calculatedTitle,
        },
      };
    });
  };

  // Handler for product fields change
  const handleProductChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | NumericFormatChangeEvent
  ) => {
    const name = e.target.name as string;
    let value: string | unknown; // Declare value to hold the extracted value

    // Determine the event type and extract the value accordingly
    if ('formattedValue' in e) {
      // This means the event is of type NumericFormatChangeEvent
      value = e.target.value; // Use the formatted value or raw value
    } else {
      // Handle standard input events
      value = e.target.value;
    }

    setAuctionItem((prev) => {
      const updatedProduct = {
        ...prev.product,
        [name]: value,
      };

      // Get the calculateTitle function based on productType
      const calculateTitleFn = productTypeFieldsMap[updatedProduct.productType]?.calculateTitle;

      const reservePriceStr = prev.reservePrice > 0 ? '' : 'NoReservePrice ';

      // Calculate the new title
      const calculatedTitle = calculateTitleFn ? calculateTitleFn(updatedProduct) : '';

      return {
        ...prev,
        product: {
          ...updatedProduct,
          title: reservePriceStr + calculatedTitle,
        },
      };
    });
  };

  // Handler for auction item fields change
  const handleAuctionItemChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = e.target.name as string;
    const value = e.target.value as string;
    setAuctionItem((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'reservePrice') {
      setAuctionItem((prev) => {
        const reservePriceStr = Number(value) > 0 ? '' : 'NoReservePrice ';
        const calculateTitleFn = productTypeFieldsMap[prev.product.productType]?.calculateTitle;
        const calculatedTitle = calculateTitleFn ? calculateTitleFn(prev.product) : '';
        return {
          ...prev,
          product: {
            ...prev.product,
            title: reservePriceStr + calculatedTitle,
          },
        };
      });
    }
  };

  // Handler for image uploads (coverImage or images)
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>, imageType: 'coverImage' | 'images') => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);

      // Compress images
      const compressedFiles = await Promise.all(
        newFiles.map(async (file) => {
          try {
            const compressedFile = await imageCompression(file, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            });
            const fileExtension = file.name.split('.').pop();
            const compressedFileWithExtension = new File([compressedFile], `${file.name}.${fileExtension}`, {
              type: file.type,
            });
            return compressedFileWithExtension;
          } catch (error) {
            console.error('Error compressing image:', error);
            return file; // Fallback to original file if compression fails
          }
        })
      );

      if (imageType === 'coverImage') {
        // Only one cover image
        const coverFile = compressedFiles[0];
        const coverImageItem: ImageItem = {
          id: `cover-${Date.now()}`,
          src: URL.createObjectURL(coverFile),
          file: coverFile,
          existing: false,
        };

        setCoverImage(coverImageItem);

        // Clear existing cover image URL
        setAuctionItem((prev) => ({
          ...prev,
          img: '', // Since the new cover image is not yet uploaded, we clear the img field
        }));
      } else {
        // Handle additional images
        const newImageItems: ImageItem[] = compressedFiles.map((file, index) => ({
          id: `new-${Date.now()}-${index}`,
          src: URL.createObjectURL(file),
          file,
          existing: false,
        }));

        // Check if the new selection would exceed the limit
        if (images.length + newImageItems.length > MAX_IMAGE_COUNT) {
          alert(`You can only upload a maximum of ${MAX_IMAGE_COUNT} images.`);
          return;
        }

        setImages((prev) => [...prev, ...newImageItems]);
      }
    }
  };

  const handleCancel = async () => {
    // Optionally, confirm with the user before navigating away
    const confirmCancel = await confirm({
      title: 'Cancel',
      description: 'Are you sure you want to cancel? All unsaved changes will be lost.',
      confirmText: 'Yes',
      cancelText: 'No',
    });

    if (confirmCancel) {
      navigate('/auctions'); // Replace '/auctions' with your desired route
    }
  };

  useEffect(() => {
    if (isEditMode && id) {
      setLoading(true);
      // Fetch the existing auction data using Axios
      apiClient
        .get<AuctionItem>(`/auction-items/${id}`)
        .then((response) => {
          const data = response.data;
          setAuctionItem({
            ...data,
            // Keep startDate and endDate as strings
            startDate: data.startDate || '',
            endDate: data.endDate || '',
          });
          setSelectedProductType(data.product.productType || '');

          // Initialize images array
          const existingCoverItem: ImageItem | null = data.img
            ? {
                id: `existing-cover-${Date.now()}`,
                src: data.img,
                existing: true,
              }
            : null;

          // When initializing images
          const existingImageItems: ImageItem[] = data.images
            ? data.images.map((imgUrl, index) => ({
                id: `existing-${Date.now()}-${index}`,
                src: imgUrl,
                existing: true,
              }))
            : [];
          if (existingCoverItem) {
            setCoverImage(existingCoverItem);
          }
          setImages(existingImageItems);
        })
        .catch((err) => {
          console.error(err);
          setError('Failed to load auction data.');
        })
        .finally(() => {
          setLoading(false);
        });
      if (user.role === 'Admin') {
        apiClient
          .get<Auction[]>('/auctions')
          .then((response) => {
            setAuctionList(response.data);
          })
          .catch((err) => {
            console.error(err);
            setError('Failed to load auction data.');
          });
      }
    }
  }, [isEditMode, id]);

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();

    // Append auctionItem fields
    Object.keys(auctionItem).forEach((key) => {
      if (
        key !== 'product' &&
        key !== 'images' &&
        key !== 'img' &&
        key !== 'bids' &&
        key !== 'quickBids' &&
        key !== '_id' &&
        key !== 'auction'
      ) {
        const value = (auctionItem as any)[key];
        // Check if the value is an array
        if (Array.isArray(value)) {
          // Append each value in the array separately
          value.forEach((item) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, String(value));
        }
        console.log(key, value);
      }
    });

    // Append product fields
    Object.keys(auctionItem.product).forEach((key) => {
      const value = (auctionItem.product as any)[key];
      // Check if the value is an array
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(`product[${key}]`, item);
        });
      } else {
        formData.append(`product[${key}]`, String(value));
      }
    });

    // Append images
    if (coverImage) {
      if (coverImage.existing) {
        formData.append('existingCoverImage', coverImage.src);
      } else if (coverImage.file) {
        formData.append('coverImage', coverImage.file);
      }
    }

    images.forEach((image) => {
      if (image.existing) {
        formData.append('existingImages', image.src);
      } else if (image.file) {
        formData.append('images', image.file);
      }
    });

    if (user.role === 'Admin' && auctionItem.auction) {
      formData.append('auction', auctionItem.auction._id);
    }

    let fetchId = id;
    let fetchIsEditMode = isEditMode;
    if (auctionItem._id && auctionItem._id !== '') {
      formData.append('_id', auctionItem._id);
      setIsEditMode(true);
      fetchId = auctionItem._id;
      fetchIsEditMode = true;
    }

    try {
      const url = fetchIsEditMode ? `/auction-items/${fetchId}` : '/auction-items';
      const method = fetchIsEditMode ? 'put' : 'post';

      const response = await apiClient.request({
        method: method,
        url: url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Axios automatically rejects promises for non-2xx status codes
      if (response.status === 200 || response.status === 201) {
        // Handle successful response
        console.log(isEditMode ? 'Auction item updated successfully' : 'Auction item created successfully');
        navigate('/auctions'); // Redirect to auction list or detail page
      } else {
        // Handle unexpected status codes
        console.error(isEditMode ? 'Error updating auction item:' : 'Error creating auction item:', response.data);
        alert(isEditMode ? 'Failed to update auction item.' : 'Failed to create auction item.');
      }
    } catch (error: any) {
      // Handle errors
      if (error.response) {
        // Server responded with a status other than 2xx
        const errorMessage = error.response.data?.message || 'Failed to submit auction data.';
        console.error(isEditMode ? 'Error updating auction item:' : 'Error creating auction item:', errorMessage);
        alert(isEditMode ? 'Failed to update auction item.' : 'Failed to create auction item.');
      } else if (error.request) {
        // Request was made but no response received
        console.error('No response received:', error.request);
        alert('No response from server. Please try again later.');
      } else {
        // Something else happened while setting up the request
        console.error('Error:', error.message);
        alert('An unexpected error occurred. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  // Function to get fields based on selected product type
  const getProductTypeFields = (): FieldDefinition[] => {
    return productTypeFieldsMap[selectedProductType]?.fields || [];
  };

  // Function to handle analyze completion
  const handleAnalyzeComplete = (data: any) => {
    // Set the auction item state with the data from the server
    setAuctionItem((prev) => ({
      ...prev,
      ...data,
      product: {
        ...prev.product,
        ...data.product,
      },
    }));

    setSelectedProductType(data.product.productType || '');
  };

  const handleAnalyze = () => {
    setAnalyzeModalOpen(true);
  };

  // Function to update the cover image URL in the auction item
  const updateAuctionItemImage = (imageUrl: string) => {
    setAuctionItem((prev) => ({
      ...prev,
      img: imageUrl,
    }));
  };

  const canAnalyze = () => {
    return images.length > 0;
  };

  if (loading) {
    // Show a loading spinner while fetching data
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    // Show an error message if fetching data failed
    return (
      <Container maxWidth="md" className="main-container">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <div className="add-auction-page-container">
      <Container maxWidth="md" className="main-container">
        <Typography variant="h4" component="h1" gutterBottom>
          {isEditMode ? 'Edit Auction Item' : 'Add New Auction Item'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Paper elevation={3} className="paper-container">
            {(!canAnalyze() || selectedProductType === '') && (
              <Box mt={2}>
                <Typography variant="h6" sx={{ color: 'red' }}>
                  Add Images & Select "Item Category" Below.
                  <br />
                  Then Recommand To User Analyze With BlingLong AI
                </Typography>
              </Box>
            )}

            {/* Image Upload Section */}
            <ImageUploadSection
              coverImage={coverImage}
              setCoverImage={setCoverImage}
              images={images}
              setImages={setImages}
              handleImageChange={handleImageChange}
              handleRemoveImage={handleRemoveImage}
              updateAuctionItemImage={updateAuctionItemImage}
            />
            <AuctionDetailsForm
              auctionItem={auctionItem}
              setAuctionItem={setAuctionItem}
              handleAuctionItemChange={handleAuctionItemChange}
              handleProductChange={handleProductChange}
              handleProductTypeSelection={handleProductTypeSelection}
              selectedProductType={selectedProductType}
              getProductTypeFields={getProductTypeFields}
              renderFields={RenderFields}
              user={user} // Pass the user prop
              canAnalyze={canAnalyze} // Pass the canAnalyze function
              handleAnalyze={handleAnalyze} // Pass the handleAnalyze function
              auctionList={auctionList}
            />
          </Paper>
          <div className="save-button-container" style={{ marginTop: '20px' }}>
            <Button variant="contained" color="primary" type="submit" disabled={submitting}>
              {submitting ? (isEditMode ? 'Updating...' : 'Creating...') : isEditMode ? 'Update Item' : 'Create Item'}
            </Button>
            <Button
              variant="outlined"
              color="warning"
              onClick={handleCancel}
              disabled={submitting}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button>
          </div>

          {/* Include the AnalyzeModal component */}
          <AnalyzeModal
            open={analyzeModalOpen}
            onClose={() => setAnalyzeModalOpen(false)}
            images={images}
            coverImage={coverImage} // Add this line
            onAnalyzeComplete={handleAnalyzeComplete}
            auctionItem={auctionItem}
          />
        </form>
      </Container>
    </div>
  );
};

export default AddAuctionPage;
