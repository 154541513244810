// src/components/AuctionProgressSidebar.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  CircularProgress,
  Grid2,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AuctionItem, Bid } from '../models/interfaces';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useNavigate } from 'react-router-dom';
import { useConfirmation } from '../contexts/ConfirmationContext';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth
import { enqueueSnackbar } from 'notistack'; // Import enqueueSnackbar for notifications
import apiClient from './apiClient'; // Import your Axios instance
import { handleAxiosError } from '../utils/handleAxiosError'; // Import the utility function
import CountdownTimer from './CountdownTimer';

interface AuctionProgressSidebarProps {
  item: AuctionItem;
  bidAmount: number;
  setBidAmount: (amount: number) => void;
  placeBid: () => void;
  setMaxBid: () => void;
  onApplyFilters: (filters: any) => void;
}

const AuctionProgressSidebar: React.FC<AuctionProgressSidebarProps> = ({
  item,
  bidAmount,
  setBidAmount,
  placeBid,
  setMaxBid,
  onApplyFilters,
}) => {
  const [bidHistoryOpen, setBidHistoryOpen] = useState(false);
  const [bidHistory, setBidHistory] = useState<Bid[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { socket, isConnected } = useWebSocket(); // Access socket and connection status
  const navigate = useNavigate();
  const { requireAuth, isLoggedIn } = useAuth(); // Get Auth0 functions

  // Fetch bid history from the server
  const fetchBidHistoryAction = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get(`/auction-items/${item._id}/bid-history`, {
        params: { page: 1, limit: 10 },
      });
      setBidHistory(response.data.bids);
    } catch (err: any) {
      const message = handleAxiosError(err) || 'Failed to fetch bid history';
      setError(message);
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchBidHistory = async () => {
    try {
      await requireAuth(fetchBidHistoryAction);
    } catch (error: any) {
      console.error('Authentication required:', error);
      enqueueSnackbar('You must be logged in to perform this action.', {
        variant: 'warning',
      });
    }
  };

  // Toggle bid history visibility and fetch data every time it's opened
  const handleBidHistoryToggle = () => {
    if (!bidHistoryOpen) fetchBidHistory();
    setBidHistoryOpen(!bidHistoryOpen);
  };

  const handleSupplierClick = () => {
    if (item.supplier) {
      onApplyFilters({ supplier: item.supplier._id });
      navigate('/supplier/' + item.supplier._id);
    }
  };

  const formatNumber = (number: any) => (typeof number === 'number' ? number.toLocaleString() : number);

  const registerBid = async () => {
    try {
      await requireAuth(); // Ensure authentication
      if (!isLoggedIn) {
        enqueueSnackbar('You must be logged in to perform this action.', { variant: 'warning' });
        return;
      }
      setLoading(true);
      setError(null);

      try {
        await apiClient.post(`/auction-items/${item._id}/register-bid`);
        enqueueSnackbar('Successfully registered for the auction!', { variant: 'success' });
      } catch (err: any) {
        const message = handleAxiosError(err) || 'Failed to register bid';
        setError(message);
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error('Authentication required:', error);
      // Optionally show a message to the user
      enqueueSnackbar('You must be logged in to perform this action.', {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    console.log('AuctionProgressSidebar useEffect: Socket:', socket, 'isConnected:', isConnected);
    if (socket && isConnected && bidHistoryOpen) {
      const handlePriceUpdate = (data: { auctionId: string; newPrice: number }) => {
        console.log('AuctionProgressSidebar: Received priceUpdated event:', data);
        if (data.auctionId === item._id) {
          fetchBidHistory();
          setCurrentBid(data.newPrice); // Update current bid locally
        }
      };

      console.log('AuctionProgressSidebar: Socket is connected, setting up listeners');
      socket.on('priceUpdated', handlePriceUpdate);

      // Cleanup on unmount or when dependencies change
      return () => {
        socket.off('priceUpdated', handlePriceUpdate);
      };
    }
  }, [socket, isConnected, item._id, bidHistoryOpen]);

  // State to manage current bid
  const [currentBid, setCurrentBid] = useState<number>(item.currentBid);

  // Update currentBid if item.currentBid changes
  useEffect(() => {
    setCurrentBid(item.currentBid);
  }, [item.currentBid]);

  return (
    <Box
      sx={{
        marginLeft: { xs: '6px', md: '0px' },
        marginRight: { xs: '6px', md: '70px' },
        padding: { xs: '10px', md: '34px' },
        borderRadius: '8px',
        boxShadow: '0px 4px 20px 0px rgba(86, 39, 255, 0.25)',
        backgroundColor: 'background.paper',
      }}
    >
      <Grid2 container spacing={1} alignItems="flex-start" flexDirection={'column'}>
        {/* Using standard typography variant */}
        <Typography variant="body2" color="textPrimary">
          Current Bid:
        </Typography>
        <Typography variant="h4" color="primary">
          ${formatNumber(currentBid)}
        </Typography>
      </Grid2>
      {item.startingPrice > 0 ? (
        <Typography variant="subtitle2" sx={{ marginBottom: '8px' }}>
          Reserve Price: ${formatNumber(item.startingPrice)}
        </Typography>
      ) : (
        <Typography variant="subtitle2" sx={{ marginBottom: '8px' }}>
          No Reserve Price
        </Typography>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <img
          src="/logo192.png"
          alt="Expert"
          style={{
            width: '72px',
            height: '72px',
            marginRight: '10px',
            borderRadius: '36px',
          }}
        />
        <Box>
          {item.expertEstimateMin !== undefined && item.expertEstimateMin > 0 && (
            <Typography variant="body1">
              Expert Estimate: ${formatNumber(item.expertEstimateMin)} - $
              {formatNumber(item.expertEstimateMax) ?? 'N/A'}
            </Typography>
          )}
          <Typography variant="body2">Examined by BlingLong AI</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: { xs: '30px 4px 30px 4px', md: '30px 40px 30px 40px' },
        }}
      >
        {item.quickBids.map((amount) => (
          <Button
            key={amount}
            variant="outlined"
            sx={{
              padding: '8px 16px',
              margin: '0 4px',
              borderRadius: '50px',
              fontSize: '20px',
              fontWeight: 400,
              color: 'text.primary',
              backgroundColor: 'background.paper',
              boxShadow: '0px 4px 20px 0px rgba(86, 39, 255, 0.25)',
              borderColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'background.paper',
                color: 'text.primary',
                boxShadow: '0px 6px 25px 0px rgba(86, 39, 255, 0.38)',
                borderColor: 'secondary.main',
              },
            }}
            onClick={() => setBidAmount(amount)}
          >
            ${formatNumber(amount)}
          </Button>
        ))}
      </Box>

      {/* Manual bid entry */}
      <TextField
        label="Enter Your Bid"
        type="text" // Keeping it text to validate user input more effectively
        fullWidth
        value={bidAmount !== 0 ? bidAmount : ''} // Prevent displaying 0 when input is cleared
        onChange={(e) => {
          const value = e.target.value;
          // Validate numeric input (allowing only digits and decimal point)
          if (/^\d*\.?\d*$/.test(value)) {
            setBidAmount(value === '' ? 0 : Number(value)); // Handle empty input
          }
        }}
        sx={{
          marginBottom: '16px',
          '& .MuiInputBase-input': { fontSize: '1.2rem' },
          '& .MuiInputLabel-root': { fontSize: '1rem' },
        }}
      />

      {new Date(item.startDate).getTime() > Date.now() ? (
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Starting in: <CountdownTimer endDate={item.startDate} />
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', gap: '8px', marginBottom: '16px' }}>
          <Button
            variant="contained"
            onClick={placeBid}
            sx={{ backgroundColor: 'primary.main', color: '#fff' }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Place Bid'}
          </Button>
          <Button variant="outlined" onClick={setMaxBid} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Max Bid'}
          </Button>
        </Box>
      )}

      <Typography variant="body2" sx={{ marginBottom: '8px' }}>
        Closes: {new Date(item.endDate).toLocaleString()}
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '8px' }}>
        Max Bid: {item.maxBid ? `$${item.maxBid}` : 'Not Set'}
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '8px' }}>
        Buyer Protection fee: 10%
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '8px' }}>
        Shipping: $35 from {item.supplier ? item.supplier.country : 'N/A'}
      </Typography>
      <Typography variant="body2">
        Supplier:{' '}
        <span onClick={handleSupplierClick} style={{ cursor: 'pointer', color: '#6200ea' }}>
          {item.supplier?.name || 'N/A'}
        </span>
      </Typography>

      {item.buyItNowPrice > 0 && (
        <Typography variant="body2">Buy It Now: ${formatNumber(item.buyItNowPrice)}</Typography>
      )}

      <Box mt={2}>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleBidHistoryToggle}
          endIcon={bidHistoryOpen ? <ExpandLess /> : <ExpandMore />}
          sx={{
            marginBottom: '8px',
            color: 'primary.main',
            borderColor: 'primary.main',
            '&:hover': { backgroundColor: 'primary.main', color: '#fff' },
          }}
        >
          <Typography variant="body1">Bid History</Typography>
        </Button>
        <Collapse in={bidHistoryOpen} timeout="auto" unmountOnExit>
          <Box sx={{ marginTop: '8px' }}>
            {loading ? (
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography color="error" textAlign="center">
                {error}
              </Typography>
            ) : (
              <List>
                {bidHistory.length > 0 ? (
                  bidHistory.map((bid, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={`$${formatNumber(bid.amount)} by ${bid.bidder}`}
                          secondary={new Date(bid.timestamp).toLocaleString()}
                          primaryTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
                          secondaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                        />
                      </ListItem>
                      {index < bidHistory.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No bids yet." />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default AuctionProgressSidebar;
