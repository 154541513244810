// src/App.tsx

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import FilterSidebar from './components/filters/FilterSidebar';
import HomePage from './pages/HomePage';
import AuctionItemPage from './pages/AuctionItemPage';
import UserProfileSettingsPage from './components/settings/UserProfileSettingsPage';
import './App.css';
import AddAuctionPage from './components/add-auction/AddAuctionPage';
import CreateSupplierPage from './components/create-supplier/CreateSupplierPage';
import PrivateRoute from './components/PrivateRoute';
import SupplierListPage from './components/supplier/SupplierListPage';
import UserListPage from './components/users/UserListPage';
import EditUserPage from './components/users/EditUserPage';
import AuctionListPage from './components/auction/AuctionListPage/AuctionListPage';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { AppProvider } from './components/chat/AppContext';
import ChatButton from './components/chat/ChatButton';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane, faVolumeUp, faHandPaper, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationProvider } from './contexts/ConfirmationContext';
import LandingPage from './pages/LandingPage';
import { useAuth } from './contexts/AuthContext';
import { Typography, useMediaQuery } from '@mui/material';
import LoginPage from './components/login/LoginPage';
import SignUpPage from './components/login/SignUpPage';
import ConfirmSignUpPage from './components/login/ConfirmSignUpPage';
import ResendCodePage from './components/login/ResendCodePage';
import OAuth2RedirectHandler from './components/login/OAuth2RedirectHandler';
import OnboardingPage from './components/onboarding/OnboardingPage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { MobileVerificationProvider } from './components/onboarding/MobileVerificationContext';
import AuctionManagementPage from './components/auction/AuctionManagementPage/AuctionManagementPage';
import AddEditAuctionPage from './components/auction/AuctionManagementPage/AddEditAuctionPage';

library.add(faPaperPlane, faVolumeUp, faHandPaper, faMicrophone);

const stipeKey = process.env.REACT_APP_STRIPE_KEY || '';
const stripePromise = loadStripe(stipeKey);

const App: React.FC = () => {
  const [filters, setFilters] = useState<any>({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const chatContextRef = useRef<any>({});
  const { user, isLoggedIn, loading, logout, getAccessToken } = useAuth();
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isInstallVisible, setIsInstallVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const API_BASE_URL =
    window.location.origin === 'http://localhost:3000' ? 'http://localhost:5000' : process.env.REACT_APP_API_BASE_URL;

  const isRouteWithSidebar = () => {
    return (
      location.pathname === '/' ||
      location.pathname.startsWith('/category/') ||
      location.pathname.startsWith('/supplier/') ||
      location.pathname.startsWith('/ai/search')
    );
  };

  const isAuthPath = () => {
    return location.pathname.startsWith('/auth/');
  };

  // Detect if the device is mobile
  const isMobile = useMediaQuery('(max-width:767px)');

  // Compute isSidebarVisible based on screen size and filters
  const shouldShowDesktopSidebar =
    (!isMobile &&
      isRouteWithSidebar() &&
      filters &&
      Object.keys(filters).length > 0 &&
      ((filters.categories && filters.categories.length > 0) ||
        (filters.dynamicFilters && Object.keys(filters.dynamicFilters).length > 0))) ||
    location.pathname === '/ai/search';

  console.log('IsMobile:', isMobile);
  console.log('isRouteWithSidebar:', isRouteWithSidebar());
  console.log('Filters:', filters);
  console.log('Should show desktop sidebar:', shouldShowDesktopSidebar);

  useEffect(() => {
    if (!isMobile && isFilterOpen !== shouldShowDesktopSidebar) {
      changeFilterOpenState(shouldShowDesktopSidebar);
    }
  }, [shouldShowDesktopSidebar, isFilterOpen, isMobile]);

  const handleLogout = () => {
    console.log('Logging out ..');
    logout();
  };

  const updateContext = (context: any) => {
    chatContextRef.current = context;
    console.log('Chat context updated:', context);
  };

  const changeFilterOpenState = (state: boolean) => {
    console.log('Changing filter open state:', state);
    setIsFilterOpen(state);
  };

  const getChatContext = () => {
    console.log('Getting chat context:', chatContextRef.current);
    return chatContextRef.current;
  };

  const handleApplyFilters = useCallback((appliedFilters: any) => {
    console.log('App Applied filters:', appliedFilters);
    setFilters(appliedFilters);
  }, []);

  useEffect(() => {
    console.log('Location changed:', location.pathname);
    if (!isRouteWithSidebar()) {
      changeFilterOpenState(false);
      console.log('Closing filter sidebar for route:', location.pathname);
    }
  }, [location.pathname, isRouteWithSidebar]);

  // Install Add to home screen
  useEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  // Remove the setAccessTokenGetter useEffect as it's no longer needed
  /*
  useEffect(() => {
    setAccessTokenGetter(getAccessToken);
  }, [getAccessToken]);
  */

  const handleInstallClick = () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();

    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
      setIsInstallVisible(false);
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  console.log('User:', user);
  console.log('IsLoggedIn:', isLoggedIn);
  console.log('Filters:', filters);
  console.log('SelectedCategory:', selectedCategory);

  return (
    <MobileVerificationProvider>
      <AppProvider>
        <ConfirmationProvider>
          {!isAuthPath() && (
            <Header
              onOpenFilter={() => changeFilterOpenState(!isFilterOpen)}
              user={user}
              onLogout={handleLogout}
              onApplyFilters={handleApplyFilters}
              filters={filters}
              onCloseFilter={() => changeFilterOpenState(false)}
            />
          )}
          <div className="app-container">
            <FilterSidebar
              isOpen={isFilterOpen}
              onClose={() => changeFilterOpenState(false)}
              selectedCategory={selectedCategory}
              filters={filters}
              onApplyFilters={handleApplyFilters}
              isSidebarVisible={shouldShowDesktopSidebar}
            />
            <div className={`main-content ${isFilterOpen ? 'sidebar-open' : ''}`}>
              <Routes>
                {/* Public Routes */}
                <Route path="/auth/login" element={<LoginPage />} />
                <Route path="/auth/signup" element={<SignUpPage />} />
                <Route path="/auth/confirm-signup" element={<ConfirmSignUpPage />} />
                <Route path="/auth/resend-code" element={<ResendCodePage />} />
                <Route path="/oauth2/idpresponse" element={<OAuth2RedirectHandler />} />
                <Route
                  path="/auth/onboarding"
                  element={
                    <Elements stripe={stripePromise}>
                      <OnboardingPage />
                    </Elements>
                  }
                />

                {/* Home and Landing */}
                <Route
                  path="/"
                  element={
                    filters && Object.keys(filters).length === 0 ? (
                      <LandingPage />
                    ) : (
                      <HomePage filters={filters} onApplyFilters={handleApplyFilters} onChatContext={updateContext} />
                    )
                  }
                />

                {/* Authenticated Routes */}
                <Route
                  path="/auction/:id"
                  element={<AuctionItemPage onApplyFilters={handleApplyFilters} onChatContext={updateContext} />}
                />
                <Route
                  path="/ai/search"
                  element={
                    <HomePage filters={filters} onApplyFilters={handleApplyFilters} onChatContext={updateContext} />
                  }
                />
                <Route
                  path="/category/:category"
                  element={
                    <HomePage filters={filters} onApplyFilters={handleApplyFilters} onChatContext={updateContext} />
                  }
                />
                <Route
                  path="/supplier/:id"
                  element={
                    <HomePage filters={filters} onApplyFilters={handleApplyFilters} onChatContext={updateContext} />
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute requiredRoles={['Admin', 'Supplier', 'LoginUser']}>
                      <UserProfileSettingsPage />
                    </PrivateRoute>
                  }
                />

                {/* Suppliers and Admins */}
                {user && (user.role === 'Supplier' || user.role === 'Admin') && (
                  <>
                    <Route
                      path="/add-auction-item"
                      element={
                        <PrivateRoute requiredRoles={['Admin', 'Supplier']}>
                          <AddAuctionPage user={user} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-auction-item/:id"
                      element={
                        <PrivateRoute requiredRoles={['Admin', 'Supplier']}>
                          <AddAuctionPage user={user} />
                        </PrivateRoute>
                      }
                    />
                  </>
                )}
                <Route
                  path="/auctions"
                  element={
                    <PrivateRoute requiredRoles={['Admin', 'Supplier']}>
                      {user ? <AuctionListPage loginUser={user} /> : <div>Loading...</div>}
                    </PrivateRoute>
                  }
                />

                {/* Admin Only */}
                {user && user.role === 'Admin' && (
                  <>
                    <Route path="/auction-management" element={<AuctionManagementPage loginUser={user} />} />
                    <Route path="/auctions/add" element={<AddEditAuctionPage />} />
                    <Route path="/auctions/edit/:id" element={<AddEditAuctionPage />} />
                    <Route
                      path="/create-supplier"
                      element={
                        <PrivateRoute requiredRoles={['Admin']}>
                          <CreateSupplierPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/suppliers"
                      element={
                        <PrivateRoute requiredRoles={['Admin']}>
                          <SupplierListPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-supplier/:id"
                      element={
                        <PrivateRoute requiredRoles={['Admin']}>
                          <CreateSupplierPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <PrivateRoute requiredRoles={['Admin']}>
                          <UserListPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-user/:id"
                      element={
                        <PrivateRoute requiredRoles={['Admin']}>
                          {user ? <EditUserPage loginUser={user} /> : <div>Loading...</div>}
                        </PrivateRoute>
                      }
                    />
                  </>
                )}

                {/* Unauthorized Route */}
                <Route
                  path="/unauthorized"
                  element={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="80vh"
                    >
                      <Typography variant="h4" color="error">
                        403 - Unauthorized
                      </Typography>
                      <Typography variant="body1">You do not have permission to view this page.</Typography>
                    </Box>
                  }
                />

                {/* Fallback Route */}
                <Route
                  path="*"
                  element={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="80vh"
                    >
                      <Typography variant="h4" color="error">
                        404 - Page Not Found
                      </Typography>
                      <Typography variant="body1">The page you're looking for doesn't exist.</Typography>
                    </Box>
                  }
                />
                <Route path="/LandingPage" element={<LandingPage />} />
              </Routes>
            </div>
          </div>
          {!isAuthPath() && (
            <ChatButton
              getChatContext={getChatContext}
              onOpenFilters={() => changeFilterOpenState(true)}
              user={user}
              onApplyFilters={handleApplyFilters}
              filters={filters}
            />
          )}
        </ConfirmationProvider>
      </AppProvider>
    </MobileVerificationProvider>
  );
};

export default App;
