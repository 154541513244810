// src/components/login/ConfirmSignUpPage.tsx

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography, Container, Alert, useTheme, useMediaQuery } from '@mui/material';

interface LocationState {
  email?: string;
  password?: string;
}

const ConfirmSignUpPage: React.FC = () => {
  const { confirmSignUp, login, resendSignUp } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const [email, setEmail] = useState<string>(state?.email || '');
  const [password, setPassword] = useState<string>(state?.password || '');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [resendSuccess, setResendSuccess] = useState<string>('');
  const [resendError, setResendError] = useState<string>('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!state?.email) {
      // Optionally, redirect to sign-up if email is not provided
      navigate('/auth/signup');
    }
  }, [state, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setResendError('');
    setResendSuccess('');
    setLoading(true);
    try {
      await confirmSignUp(email, code);
      setSuccess('Account confirmed successfully!');
      // Auto-login the user after confirmation
      await login(email, password);
      navigate('/auth/onboarding'); // Redirect to onboarding
    } catch (err: any) {
      setError(err.message || 'Confirmation failed');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    setResendError('');
    setResendSuccess('');
    setResendLoading(true);
    try {
      await resendSignUp(email);
      setResendSuccess('Verification code resent successfully! Please check your email.');
    } catch (err: any) {
      setResendError(err.message || 'Failed to resend code');
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url('/background1.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm">
        <Box sx={{ minHeight: '30px' }} />
        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: 3,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            mx: isMobile ? 'auto' : 'unset',
            ml: isMobile ? 'auto' : 4,
            mr: isMobile ? 'auto' : 'unset',
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <Typography component="h1" variant="h5">
            Confirm Your Account
          </Typography>
          {error && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
              {success}
            </Alert>
          )}
          {resendError && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {resendError}
            </Alert>
          )}
          {resendSuccess && (
            <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
              {resendSuccess}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              InputProps={{
                readOnly: true,
              }}
              aria-label="Email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Verification Code"
              name="code"
              autoComplete="one-time-code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              helperText="Enter the code sent to your email"
              aria-label="Verification Code"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
              aria-label="Confirm"
            >
              {loading ? 'Confirming...' : 'Confirm'}
            </Button>
            <Typography variant="body2" align="center">
              Didn't receive a code?{' '}
              <Button
                onClick={handleResendCode}
                disabled={resendLoading}
                variant="text"
                color="secondary"
                sx={{ textTransform: 'none', p: 0 }}
                aria-label="Resend Code"
              >
                {resendLoading ? 'Resending...' : 'Resend Code'}
              </Button>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ConfirmSignUpPage;
