// src/components/auction/AddEditAuctionPage.tsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Box,
  Grid2,
  Snackbar,
  Alert,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Using Dayjs
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../../apiClient';
import { handleAxiosError } from '../../../utils/handleAxiosError';
import dayjs, { Dayjs } from 'dayjs';
import AuctionListPage from '../AuctionListPage/AuctionListPage';
import { useAuth } from '../../../contexts/AuthContext';

interface Auction {
  _id?: string;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  status: 'Upcoming' | 'Active' | 'Closed'; // Define the possible statuses
  auctionItems: string[];
}

const auctionStatuses = [
  { value: 'Upcoming', label: 'Upcoming' },
  { value: 'Active', label: 'Active' },
  { value: 'Closed', label: 'Closed' },
];

const AddEditAuctionPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEditMode = Boolean(id);
  const navigate = useNavigate();
  const { user } = useAuth();

  const [auction, setAuction] = useState<Auction>({
    name: '',
    description: '',
    startTime: new Date().toISOString(),
    endTime: new Date().toISOString(),
    status: 'Upcoming',
    auctionItems: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // State for DateTimePickers using Dayjs
  const [startTime, setStartTime] = useState<Dayjs | null>(dayjs(auction.startTime));
  const [endTime, setEndTime] = useState<Dayjs | null>(dayjs(auction.endTime));

  useEffect(() => {
    if (isEditMode && id) {
      fetchAuction(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, id]);

  const fetchAuction = async (auctionId: string) => {
    setLoading(true);
    try {
      const response = await apiClient.get<Auction>(`/auctions/${auctionId}`);
      setAuction(response.data);
      setStartTime(dayjs(response.data.startTime));
      setEndTime(dayjs(response.data.endTime));
    } catch (error) {
      console.error('Error fetching auction:', error);
      const message = handleAxiosError(error) || 'Failed to fetch auction';
      setErrorMessage(message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setAuction((prev) => ({ ...prev, [name]: value }));
  };

  const handleStartTimeChange = (date: Dayjs | null) => {
    setStartTime(date);
    setAuction((prev) => ({
      ...prev,
      startTime: date ? date.toISOString() : prev.startTime,
    }));
  };

  const handleEndTimeChange = (date: Dayjs | null) => {
    setEndTime(date);
    setAuction((prev) => ({
      ...prev,
      endTime: date ? date.toISOString() : prev.endTime,
    }));
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAuction((prev) => ({ ...prev, status: value as Auction['status'] }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      if (isEditMode && id) {
        await apiClient.put(`/auctions/${id}`, auction);
      } else {
        await apiClient.post('/auctions', auction);
      }
      navigate('/auction-management');
    } catch (error) {
      console.error('Error saving auction:', error);
      const message = handleAxiosError(error) || 'Failed to save auction';
      setErrorMessage(message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        {isEditMode ? 'Edit Auction' : 'Add New Auction'}
      </Typography>
      <Paper style={{ padding: '20px' }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={auction.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Description"
            name="description"
            value={auction.description}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid2 container spacing={2}>
              <Grid2 sx={{ xs: 12, sm: 6 }}>
                <DateTimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={handleStartTimeChange}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid2>
              <Grid2 sx={{ xs: 12, sm: 6 }}>
                <DateTimePicker
                  label="End Time"
                  value={endTime}
                  onChange={handleEndTimeChange}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid2>
            </Grid2>
          </LocalizationProvider>

          {/* Status Field: Visible only to Admins */}
          {user && user.role === 'Admin' && (
            <TextField
              select
              label="Status"
              name="status"
              value={auction.status}
              onChange={handleStatusChange}
              SelectProps={{
                native: true,
              }}
              fullWidth
              margin="normal"
              required
            >
              {auctionStatuses.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          )}

          {isEditMode && (
            <Box mt={4}>
              <Typography variant="h5" gutterBottom>
                Auction Items
              </Typography>
              <Paper style={{ padding: '20px' }}>
                {user && <AuctionListPage loginUser={user} auctionId={auction._id} auctionContext={true} />}{' '}
                {/* Integrate AuctionListPage */}
              </Paper>
            </Box>
          )}

          {/* Add more fields if necessary */}
          {errorMessage && (
            <Snackbar open={Boolean(errorMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          )}
          <Button type="submit" variant="contained" color="primary" disabled={submitting} style={{ marginTop: '20px' }}>
            {submitting ? 'Saving...' : isEditMode ? 'Update Auction' : 'Create Auction'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AddEditAuctionPage;
